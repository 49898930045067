import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Home from "./components/pages/main/Home";
import Projects from "./components/pages/main/Projects";
import About from "./components/pages/main/About";
import Layout from "./components/pages/main/Layout";
import NoPage from "./components/pages/main/NoPage";
import Braintumor from "./components/pages/projects/Braintumor";
import Impressum from "./components/pages/main/Impressum";
import SpikyBall from "./components/pages/projects/SpikyBall";
import CatBreed from "./components/pages/projects/CatBreed";
import MinecraftClone from "./components/pages/projects/MinecraftClone";


export default function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<Home />} />
                    <Route path="impressum" element={<Impressum/>} />
                    <Route path="minecraftClone" element={<MinecraftClone />} />
                    <Route path="braintumor" element={<Braintumor />} />
                    <Route path="catbreed" element={<CatBreed />} />
                    <Route path="spikyball" element={<SpikyBall />} />
                    <Route path="*" element={<NoPage />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
