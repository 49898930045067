import '../../css/MinecraftClone.css'
import { motion } from "framer-motion";

const MinecraftClone = () => {
    return(
        <>
            <div className={"minecraftClone"}>
                <div className={"leftSide"}>
                    <motion.div className={"box1"}
                                animate={{x: [-500, 0]}}
                    >
                        <h1 style={{textAlign: "center"}}>Minecraft Clone</h1>
                        <h2 id={"Github"}><a href={"https://github.com/Martinh779/MinecraftClone"}>Link zum Github Projekt</a></h2>
                        <p>Dies ist ein persönliches Projekt, bei dem ich eine Low-Level-Voxel-Simulation von Grund auf
                            mit C++ und OpenGL entwickle. Der Fokus liegt auf Speicherverwaltung, manueller
                            Pointer-Verwaltung, effizienten Algorithmen und direkter Kontrolle der Systemarchitektur.
                            Dieses Projekt verzichtet bewusst auf externe Bibliotheken, um die Prinzipien der
                            Entwicklung eingebetteter Systeme zu reflektieren.</p>
                    </motion.div>
                    <motion.div className={"box1"}
                                initial={{opacity: 0, scale: 0.5}}
                                animate={{ opacity: 1, scale: 1 }}
                                transition={{ duration: 0.2 }}
                    >
                        <h2>Aktuelle Funktionen:</h2>
                        <ul>
                            <li>Manuelle Speicherverwaltung: Direkte Verwendung von Pointern und benutzerdefinierten Allokatoren für die Speicherverwaltung.</li>
                            <li>3D-Rendering-Pipeline: Direkte Interaktion mit OpenGL zur Darstellung von Voxel-Daten ohne Abstraktionsschichten.</li>
                            <li>Effiziente räumliche Partitionierung: Blöcke werden über ein Chunk-System verwaltet, um die Performance und den Speicherverbrauch zu optimieren.</li>
                            <li>Kamera System: Implementierung einer frei beweglichen Kamera unter Nutzung direkter Matrixmanipulationen für vollständige Low-Level-Kontrolle.</li>
                        </ul>
                        <h2>Nächste Schritte</h2>
                        <ul>
                            <li>Physik-Engine: Integration einer einfachen Physik-Engine zur Interaktion mit der Umgebung.</li>
                            <li>Benutzeroberfläche: Implementierung einer einfachen Benutzeroberfläche zur Interaktion mit dem Spiel.</li>
                            <li>Datenkompression: Erprobung von Kompressionsalgorithmen zur effizienten Voxel Speicherung</li>
                        </ul>
                    </motion.div>
                    <motion.div className={"box1"}
                                animate={{x: [-500, 0]}}
                    >
                        <h2>Warum dieses Projekt?</h2>
                        <p>
                            Mein Ziel ist es, ein tieferes Verständnis für die Low-Level-Programmierung zu erlangen, einschließlich der direkten Speicherverwaltung, effizienter Algorithmen und der Interaktion mit der Hardware. Dieses Projekt dient als Plattform zur praxisnahen Anwendung dieser Konzepte in ressourcenbeschränkten Umgebungen.
                        </p>
                    </motion.div>
                </div>
                <motion.div
                            animate={{x: [500, 0]}}
                >
                    <img src={require("../../images/minecraftClone/Screenshot.png")} className={"box2"}/>
                </motion.div>
            </div>
        </>

    );
};

export default MinecraftClone;