import "../../css/Impressum.css";

const Impressum = () => {
    return (
        <div className="impressumBox">
            <div className="impressum">
                <h1>Impressum</h1>
                <p>Angaben gemäß § 5 DDG</p>
                <p>Martin Hertel <br/>
                    Beuchaer Str. 11<br/>
                    04318 Leipzig <br/>
                </p>
                <p><strong>Vertreten durch:</strong><br/>
                    Martin Hertel
                </p>
                <p><strong>Kontakt:</strong><br/>
                    Telefon: +49-15259567746<br/>
                    E-Mail: <a href="mailto:martin.hertel01@gmail.com">martin.hertel01@gmail.com</a>
                </p>
                <p><strong>Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:</strong><br/>
                    Martin Hertel<br/>
                    Beuchaer Str. 11<br/>
                    04318 Leipzig
                </p>
                <p><strong>Haftungsausschluss:</strong><br/><br/>
                    <strong>Haftung für Links</strong><br/><br/>
                    Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben.
                    Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten
                    Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten
                    wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum
                    Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist
                    jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von
                    Rechtsverletzungen werden wir derartige Links umgehend entfernen.
                </p>
                <p><strong>Urheberrecht</strong><br/><br/>
                    Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen
                    Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
                    Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
                    Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit
                    die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet.
                    Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
                    Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von
                    Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
                </p>
                <p>
                    Impressum vom <a href="https://www.impressum-generator.de">Impressum Generator</a> der <a
                    href="https://www.kanzlei-hasselbach.de/rechtsgebiete/familienrecht/">Kanzlei Hasselbach, Fachanwälte für
                    Familienrecht</a>
                </p>
            </div>
        </div>
    );
};

export default Impressum;
