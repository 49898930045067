import { Outlet } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import '../../css/Layout.css'
import { motion } from "framer-motion";
import Footer from "./Footer";

const Layout = () => {
    return (
        <>
            <div id="header_bar">
                <nav>
                    <ul>
                        <motion.li id={"name"}
                                   animate={{y: [-100, 0]}}
                        >
                            <HashLink to={"/"}>Martin Hertel</HashLink>
                        </motion.li>
                        <motion.li
                            animate={{y: [-100, 0]}}
                            transition={{delay: 0.1}}
                        >
                            <HashLink to={"/#about"}>Über mich</HashLink>
                        </motion.li>
                        <motion.li
                            animate={{y: [-100, 0]}}
                            transition={{delay: 0.2}}
                        >
                            <HashLink to={"/#projects"}>Projekte</HashLink>
                        </motion.li>
                        <motion.li
                            animate={{y: [-100, 0]}}
                            transition={{delay: 0.3}}
                        >
                            <HashLink to={"/#contact"}>Kontakt</HashLink>
                        </motion.li>
                        <motion.li
                            animate={{y: [-100, 0]}}
                            transition={{delay: 0.4}}
                        >
                            <a href={"https://github.com/Martinh779"}><img
                                src={require("../../images/github-mark-white.png")} width={30} height={30}/></a>
                        </motion.li>
                        <motion.li
                            animate={{y: [-100, 0]}}
                            transition={{delay: 0.4}}
                        >
                            <a href={"https://www.linkedin.com/in/martin-hertel/"}><img
                                src={require("../../images/LI-Logo.png")} width={80} height={20}/></a>
                        </motion.li>
                    </ul>
                </nav>
            </div>
            <div id="main">
                <Outlet/>
            </div>
            <Footer/>
        </>
    )
};

export default Layout;